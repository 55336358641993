import { ToastOptions } from "react-toastify";
import PartSiteSourceSlicersModel from "../models/PlanningParameters/PartSiteSourceSlicers.model";
import { ReplPlanSelectedSlicerModel } from "../models/ReplenishmentPlan/ReplPlanSlicer.model";
import SourceMasterSlicersModel from "../models/MasterTables/SourceMasterSlicers";
import { LSO_DEFAULT_GROUP_POLICY, LSO_DEFAULT_NUM_OF_WEEKS, LSO_DEFAULT_PART_CODE, LSO_DEFAULT_SITE_CODES, REPL_PLAN_DEFAULT_NUM_OF_WEEKS, REPL_PLAN_DEFAULT_PART_CODE, REPL_PLAN_DEFAULT_SITE_CODE } from "./constants";
import { LSOSelectedSlicerModel } from "../models/LotSizeOptimization/LSOSlicer.model";

/**
 * Stores / returns slicers for Planning Parameters tab in browser's local storage
 * @returns {PartSiteSourceSlicersModel} New / updated Part Site Source slicers
 */
const managePlanningParametersSlicersInStorage =
  (): PartSiteSourceSlicersModel => {
    const defaultSlicers: PartSiteSourceSlicersModel = {
      part_name: ["All"],
      source_value: ["All"],
      location_name: ["All"],
    };

    try {
      const storageSelectedSlicers = sessionStorage.getItem("selectedSlicers");

      if (storageSelectedSlicers) {
        return JSON.parse(storageSelectedSlicers);
      } else {
        sessionStorage.setItem(
          "selectedSlicers",
          JSON.stringify(defaultSlicers)
        );
        return defaultSlicers;
      }
    } catch (error: any) {
      console.error(error);
      sessionStorage.setItem("selectedSlicers", JSON.stringify(defaultSlicers));
      return defaultSlicers;
    }
  };

/**
 * Stores / returns slicers for Source Master grid in browser's local storage
 * @returns {SourceMasterSlicersModel} New / updated Source Master grid slicers
 */
const manageSourceMasterSlicersInStorage = (): SourceMasterSlicersModel => {
  const defaultSlicers: SourceMasterSlicersModel = {
    site_code: ["All"],
    supplier_code: ["All"],
  };

  try {
    const storageSelectedSlicers = sessionStorage.getItem(
      "selectedSourceMasterSlicers"
    );

    if (storageSelectedSlicers) {
      return JSON.parse(storageSelectedSlicers);
    } else {
      sessionStorage.setItem(
        "selectedSourceMasterSlicers",
        JSON.stringify(defaultSlicers)
      );
      return defaultSlicers;
    }
  } catch (error: any) {
    console.error(error);
    sessionStorage.setItem(
      "selectedSourceMasterSlicers",
      JSON.stringify(defaultSlicers)
    );
    return defaultSlicers;
  }
};

/**
 * Returns default slicers for Replenishment Plan tab
 * @returns {ReplPlanSelectedSlicerModel} Replenishment Plan tab slicers
 */
const getDefaultReplPlanSlicers = (): ReplPlanSelectedSlicerModel => {
  const defaultReplPlanSlicers: ReplPlanSelectedSlicerModel = {
    site_code: REPL_PLAN_DEFAULT_SITE_CODE,
    part_code: REPL_PLAN_DEFAULT_PART_CODE,
    number_of_weeks: REPL_PLAN_DEFAULT_NUM_OF_WEEKS,
  };

  return defaultReplPlanSlicers;
};

/**
 * Returns default slicers for Lot Size Optimization tab
 * @returns {LSOSelectedSlicerModel} Lot Size Optimization tab slicers
 */
const getDefaultLSOSlicers = (): LSOSelectedSlicerModel => {
  const defaultLSOSlicers: LSOSelectedSlicerModel = {
    site_codes: LSO_DEFAULT_SITE_CODES,
    part_code: LSO_DEFAULT_PART_CODE,
    group_policy_name: LSO_DEFAULT_GROUP_POLICY,
    number_of_weeks: LSO_DEFAULT_NUM_OF_WEEKS
  };

  return defaultLSOSlicers;
};

/**
 * Returns number in comma-separated million string format (e.g. 1,000,000)
 * @param {number} value Input number
 * @param {number} precision Numbers after decimal
 * @returns {string | null} Formatted number string
 */
const agGridNumberFormatter = (
  value: number,
  precision: number = 2
): string | null => {
  if (isNaN(value)) {
    return null;
  }

  let sansDec = value ? value.toFixed(precision) : (0).toFixed(precision);
  let formatted = sansDec
    .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replaceAll(/\.00/g, "");
  return formatted;
};

/**
 * Returns react-toastify toast options as per toast type
 * @param {"success" | "error" | "warning" | "info" | "loading"} type Toast type
 * @returns {ToastOptions<any>} Toast Options
 */
const getToastOptions = (
  type: "success" | "error" | "warning" | "info" | "loading"
): ToastOptions<any> => {
  let toastConfig: ToastOptions = {};

  switch (type) {
    case "success": {
      toastConfig = {
        autoClose: 2000,
        closeButton: true,
        className:
          "segoe-font text-dark bg-success-subtle border border-success",
      };
      break;
    }

    case "error": {
      toastConfig = {
        autoClose: false,
        closeButton: true,
        className: "segoe-font text-dark bg-danger-subtle border border-danger",
      };
      break;
    }

    case "warning": {
      toastConfig = {
        autoClose: 2000,
        closeButton: true,
        className: "segoe-font bg-warning-subtle border border-warning",
        style: {
          color: "#094780",
        },
      };
      break;
    }

    case "info": {
      toastConfig = {
        autoClose: false,
        closeButton: true,
        className: "segoe-font bg-primary-subtle border border-primary",
        style: {
          color: "#094780",
        },
      };
      break;
    }

    case "loading": {
      toastConfig = {
        autoClose: false,
        isLoading: true,
        className: "segoe-font bg-primary-subtle border border-primary",
        style: {
          color: "#094780",
        },
      };
      break;
    }

    default: {
      toastConfig = {
        autoClose: 2000,
      };
    }
  }

  return toastConfig;
};
/**
 * Remove newly inserted rows by their ID
 * @param {any} insertedRows Newly inserted rows
 * @param {any} selectedRowIds IDs of selected rows
 * @returns {any} Updated row data
 */
const removeInsertedRowsById = (
  insertedRows: any,
  selectedRowIds: any[]
): any => {
  const insertedRowIds: any[] = selectedRowIds.filter((rowId) =>
    rowId.toString().startsWith("new_")
  );
  const newInsertedRows: any = {};

  for (let id in insertedRows) {
    if (insertedRowIds.indexOf(id) === -1) {
      newInsertedRows[id] = insertedRows[id];
    }
  }

  return newInsertedRows;
};

/**
 * Returns row style for newly added rows in AG Grid
 * @param {any} params Row Style event params
 * @param {string} idFieldName Row ID field name
 * @returns CSS-styles in JSON format
 */
const newGridRowStyleHandler = (params: any, idFieldName: string): any => {
  if (params.data[idFieldName]?.toString().startsWith("new_")) {
    return {
      backgroundColor: "#D9FFD9"
    };
  }
};

export {
  managePlanningParametersSlicersInStorage,
  getDefaultReplPlanSlicers,
  getDefaultLSOSlicers,
  agGridNumberFormatter,
  getToastOptions,
  manageSourceMasterSlicersInStorage,
  removeInsertedRowsById,
  newGridRowStyleHandler
};
