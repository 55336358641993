import { configureStore } from '@reduxjs/toolkit'

import ReplenishmentTabReducer from "./slices/replenishment-tab-slice";
import PlanningParametersTabReducer from "./slices/planning-parameters-tab-slice";
import MasterTablesTabReducer from "./slices/master-tables-tab-slice";
import LotSizeOptimizationTabReducer from "./slices/lot-size-optimization-tab-slice"

const store = configureStore({
  reducer: {
    replenishmentTab: ReplenishmentTabReducer,
    lotSizeOptimizationTab: LotSizeOptimizationTabReducer,
    planningParametersTab: PlanningParametersTabReducer,
    masterTables: MasterTablesTabReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;