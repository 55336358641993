import { SizeColumnsToFitGridStrategy } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useFetch from "../../../hooks/useFetchMSAL";
import { OrderPolicyFetchAPI } from "../../../models/APIResponses.model";
import CellEditTrack from "../../../models/CellEditTrack.model";
import OrderPolicyModel from "../../../models/PlanningParameters/OrderPolicy.model";
import {
  AG_GRID_DEFAULT_COL_DEF,
  AG_GRID_MODULES,
  AG_GRID_OPTIONS,
  LOT_SIZE_OPTIMIZATION_POLICY_TAB_ID,
  ORDER_POLICY_TAB_ID,
  TOAST_CONTAINER_ID,
} from "../../../shared/constants";
import { getToastOptions, newGridRowStyleHandler, removeInsertedRowsById } from "../../../shared/functions";
import { RootState } from "../../../store";
import { planningParametersTabActions } from "../../../store/slices/planning-parameters-tab-slice";
import "../../../styles.css";
import EditableGridActions from "../../UI/EditableGridActions/EditableGridActions";

const OrderPolicyTable = (props: {
  gridHeight: string;
}) => {
  const [, fetchData] = useFetch([]);
  // States
  const [agRowData, setAgRowData] = useState<OrderPolicyModel[]>([]);
  const [agRowDataCopy, setAgRowDataCopy] = useState<OrderPolicyModel[]>([]);
  const [cellEditTracks, setCellEditTracks] = useState<{
    [key: string]: { [key: string]: CellEditTrack };
  }>({});
  const [changeTrackState, setChangeTrackState] = useState(false);
  const [insertedRows, setInsertedRows] = useState<any>({});
  const [selectedRowIds, setSelectedRowIds] = useState<any[]>([]);
  const [newRowCount, setNewRowCount] = useState<number>(1);
  const [enableDelButton, setEnableDelButton] = useState(false);

  // Variables
  const agGridRef = useRef<AgGridReact>(null);
  const ColDef: any[] = [
    {
      headerName: "Order Policy Value",
      field: "order_policy_value",
      cellDataType: "text",
      cellEditor: "agTextCellEditor",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      suppressHeaderMenuButton: true,
      sortable: false,
    },
    {
      headerName: "Multiple Orders",
      field: "multiple_orders",
      cellDataType: "boolean",
      suppressHeaderMenuButton: true,
      sortable: false,
    },
    {
      headerName: "Minimum Order Qty",
      field: "minimum_order_quantity",
      cellDataType: "number",
      cellEditor: "agNumberCellEditor",
      suppressHeaderMenuButton: true,
      sortable: false,
    },
    {
      headerName: "Maximum Order Qty",
      field: "maximum_order_quantity",
      cellDataType: "number",
      cellEditor: "agNumberCellEditor",
      suppressHeaderMenuButton: true,
      sortable: false,
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      width: 200,
      editable: true,
      ...(AG_GRID_DEFAULT_COL_DEF as any),
    };
  }, []);
  const autoSizeStrategy = useMemo<SizeColumnsToFitGridStrategy>(() => {
    return {
      type: "fitGridWidth",
      defaultMinWidth: 100,
    };
  }, []);

  // Redux variables
  const dispatch = useDispatch();
  const activeLowerSubTabId: string = useSelector((state: RootState) => state.planningParametersTab.activeSubTabId.lower);
  const tabApiDataFetched: boolean = useSelector((state: RootState) => state.planningParametersTab.tabApiDataFetched)[ORDER_POLICY_TAB_ID];
  const selectedGridSlicers = useSelector(
    (state: RootState) => state.planningParametersTab.selectedGridSlicers
  );
  const isOrderPolicyUpdated = useSelector(
    (state: RootState) => state.planningParametersTab.isOrderPolicyUpdated
  );

  /**
   * Fetches order policy data from API
   * @param {any} body Request body
   */
  const fetchOrderPolicy = useCallback(
    async (body: any): Promise<void> => {
      setChangeTrackState(false);
      const fetchingDataToastId = toast.loading(
        "Fetching Order Policy data...",
        {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("loading"),
        }
      );
      try {
        const fetchAPIResponse: AxiosResponse<OrderPolicyFetchAPI> =
          await fetchData(
            `/get-order-policy`,
            {
              method: 'POST',
              data: {}
            }
          );

        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (fetchAPIResponse.data.data) {
          setAgRowData(fetchAPIResponse.data.data);
          setAgRowDataCopy(
            JSON.parse(JSON.stringify(fetchAPIResponse.data.data))
          );
          dispatch(planningParametersTabActions.setTabApiDataFetched({
            [ORDER_POLICY_TAB_ID]: true
          }));

        } else {
          toast.error("Error in fetching Order Policy data", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } catch (error: Error | any) {
        console.error(`Request Error: ${error}`);
        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });
        toast.error("Error in fetching Order Policy data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    },
    [dispatch, fetchData]
  );

  /**
   * Event handler for when cell editing is stopped
   * @param {any} params Cell editing stop event parameters
   */
  const onCellEditingStoppedHandler = (params: any) => {
    try {
      const id = params.node.data.id;
      const colName = params.column.colId;

      if (id.toString().startsWith("new_")) {
        setInsertedRows((prev: any) => {
          let newData = prev;
          let newRow = newData[id] ? newData[id] : {};

          newRow[colName] = params.newValue;
          newData[id] = newRow;
          return newData;
        });
      } else {
        if (
          params.oldValue !== params.newValue &&
          params.newValue !== undefined
        ) {
          let currentRowNode = agGridRef.current!.api!.getRowNode(
            params.node.id
          );

          setCellEditTracks((prev) => {
            let newTrackEdits = prev;
            let cellEditTrack = newTrackEdits[id] ? newTrackEdits[id] : {};

            cellEditTrack[colName] = params.newValue;

            newTrackEdits[id] = cellEditTrack;
            return newTrackEdits;
          });

          dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'orderPolicy', value: true }));

          setChangeTrackState(true);
          agGridRef.current!.api!.applyTransaction({
            update: [currentRowNode?.data],
          });
        } else if (params.newValue === params.oldValue) {
          dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'orderPolicy', value: false }));

        }
      }
    } catch (error: any) {
      console.error(`Error: ${error}`);
    }
  };

  /**
   * Sets selected rows on selection checkbox change
   */
  const onSelectionChangedHandler = () => {
    const selectedRows = agGridRef.current!.api!.getSelectedRows();
    const selectedRowIds: any[] = selectedRows.map((el) => el.id);
    setSelectedRowIds(selectedRowIds);
  };

  /**
   * Deletes selected rows
   * @param {any[]} selectedRowIds Selected Row IDs
   */
  const deleteSelectedRowData = async (selectedRowId: any[]) => {
    let deleteToastId;

    try {
      // Delete selected newly inserted rows
      const oldDataRowIds: any[] = selectedRowIds.filter(rowId => !rowId.toString().startsWith("new_"));
      setInsertedRows((insertedRows: any) => removeInsertedRowsById(insertedRows, selectedRowIds));

      if (oldDataRowIds.length) {
        deleteToastId = toast.loading("Deleting selected rows...", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("loading"),
        });

        const deleteReqResponse: AxiosResponse =
          await fetchData(
            `/delete-order-policy`,
            {
              method: 'DELETE',
              data: { row_ids_to_delete: selectedRowId },
            }
          );

        toast.dismiss({
          id: deleteToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (deleteReqResponse.data.success) {
          setCellEditTracks({});
          setInsertedRows({});

          toast.success("Data updated successfully", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("success"),
          });

          dispatch(planningParametersTabActions.setTabApiDataFetched({
            [ORDER_POLICY_TAB_ID]: false
          }));
          dispatch(planningParametersTabActions.setIsOrderPolicyUpdated(!isOrderPolicyUpdated));

        } else {
          toast.error(deleteReqResponse.data.error, {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } else {
        const selectedRows: any[] | undefined = agGridRef.current?.api.getSelectedRows();
        agGridRef.current?.api.applyTransaction({
          remove: selectedRows
        });
      }
    } catch (error: any) {
      console.error(error);
      toast.error("Failed to delete the selected rows", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  /**
   * Updates cell values by using part site source update API
   * @param {any} cellEditTracks Cell edits in the grid
   * @param {any} insertedRows Newly inserted rows
   */
  const updateCellValues = async (
    cellEditTracks: any,
    insertedRows: any
  ): Promise<void> => {
    try {
      const updateToastId = toast.loading("Updating Order Policy data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });

      const updateReqResponse: AxiosResponse =
        await fetchData(
          `/update-order-policy`,
          {
            method: 'PUT',
            data: {
              updated_data: cellEditTracks,
              inserted_data: insertedRows,
            }
          }
        );

      toast.dismiss({
        id: updateToastId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (updateReqResponse.data.success) {
        setCellEditTracks({});
        setInsertedRows({});
        dispatch(planningParametersTabActions.setIsOrderPolicyUpdated(!isOrderPolicyUpdated));
        dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'orderPolicy', value: false }));
        toast.success("Data updated successfully", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("success"),
        });

        dispatch(planningParametersTabActions.setTabApiDataFetched({
          [ORDER_POLICY_TAB_ID]: false,
          [LOT_SIZE_OPTIMIZATION_POLICY_TAB_ID]: false
        }));
      } else if (updateReqResponse.data.error) {
        toast.error(updateReqResponse.data.error, {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: any) {
      console.error(error);
      toast.error("Error in updating the Order Policy data", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  /**
   * Event handler for save button click
   * @param {any} event Save button click event data
   */
  const onUpdateCellValuesHandler = async (event: any): Promise<void> => {
    try {
      const updatedNewRowData: any = {};
      for (let key in insertedRows) {
        if (Object.keys(insertedRows[key]).length) {
          updatedNewRowData[key] = insertedRows[key];
        }
      }

      if (
        Object.keys(cellEditTracks).length ||
        Object.keys(updatedNewRowData).length
      ) {
        updateCellValues(cellEditTracks, updatedNewRowData);
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error}`);
    }
  };

  /**
   * Event handler for reset button click
   * @param {any} params Reset button click event data
   */
  const resetCellEdits = async (params: any): Promise<void> => {
    try {
      const resetConsent: boolean = window.confirm(
        "Do you want to reset these changes?"
      );

      if (resetConsent) {
        setCellEditTracks({});
        setChangeTrackState(false);
        setAgRowDataCopy(JSON.parse(JSON.stringify([...agRowData])));
        dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'orderPolicy', value: false }));
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error}`);
    }
  };

  const gridOptions = {
    ...(AG_GRID_OPTIONS as any),
    onSelectionChanged: onSelectionChangedHandler,
    getRowStyle: (params: any) => newGridRowStyleHandler(params, "id")
  };

  /**
   * Inserts a new row into the grid
   */
  const addRowToGrid = (): void => {
    const newRowId: string = `new_${newRowCount.toString()}`;
    agGridRef.current!.api!.applyTransaction({
      add: [{ id: newRowId }],
      addIndex: 0,
    });
    setInsertedRows((insertedRows: any) => {
      return {
        ...insertedRows,
        [newRowId]: {}
      };
    });
    dispatch(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'orderPolicy', value: true }));
    setChangeTrackState(true);
    setNewRowCount(newRowCount + 1);
  };

  /**
   * Deletes selected rows from the grid
   */
  const deleteSelectedRowsFromGrid = (): void => {
    deleteSelectedRowData(selectedRowIds);
    setSelectedRowIds([]);
    setEnableDelButton(false);
  };

  // Initial data fetch
  useEffect(() => {
    if (
      !tabApiDataFetched &&
      activeLowerSubTabId === ORDER_POLICY_TAB_ID
    ) {
      fetchOrderPolicy(selectedGridSlicers);
    }
  }, [activeLowerSubTabId, tabApiDataFetched, selectedGridSlicers, fetchOrderPolicy]);

  useEffect(() => {
    if (selectedRowIds.length > 0) {
      setEnableDelButton(true);
    } else {
      setEnableDelButton(false);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (
      Object.keys(insertedRows).length === 0 &&
      Object.keys(cellEditTracks).length === 0
    ) {
      setChangeTrackState(false);
    }
  }, [insertedRows, cellEditTracks]);

  const getPivotGrid = () => {
    return (
      <>
        <div className="row mb-2">
          <div className="col offset-8 col-4 text-end">
            <EditableGridActions
              disableIf={{
                resetBtn: !changeTrackState,
                saveBtn: !changeTrackState,
                deleteRowBtn: !enableDelButton
              }}
              onClick={{
                resetBtn: resetCellEdits,
                saveBtn: onUpdateCellValuesHandler,
                addRowBtn: addRowToGrid,
                deleteRowBtn: deleteSelectedRowsFromGrid
              }}
            />
          </div>
        </div>

        <div
          className="ag-theme-balham mt-2"
          style={{
            height: props.gridHeight,
            maxHeight: props.gridHeight,
            width: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <AgGridReact
            ref={agGridRef}
            rowData={agRowDataCopy}
            columnDefs={ColDef}
            defaultColDef={defaultColDef}
            autoSizeStrategy={autoSizeStrategy}
            onCellEditingStopped={onCellEditingStoppedHandler}
            gridOptions={gridOptions}
            rowSelection={"multiple"}
            modules={AG_GRID_MODULES}
          // suppressAggFuncInHeader={true}
          />
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid">
      {getPivotGrid()}
    </div>
  );
};

export default OrderPolicyTable;
