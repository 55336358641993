import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";

import "../../../../styles.css";
import { SourceMasterFetchAPI } from "../../../../models/APIResponses.model";
import { manageSourceMasterSlicersInStorage, newGridRowStyleHandler, removeInsertedRowsById } from "../../../../shared/functions";
import { getToastOptions } from "../../../../shared/functions";
import PlanningParametersCellEditTrack from "../../../../models/PlanningParameters/PlanningParametersCellEditTrack.model";
import SourceMaster from "../../../../models/MasterTables/SourceMaster.model";
import SourceMasterSlicers from "./SourceMasterSlicers";
import SourceMasterSlicersModel from "../../../../models/MasterTables/SourceMasterSlicers";
import { RootState } from "../../../../store";
import {
  AG_GRID_DEFAULT_COL_DEF,
  AG_GRID_MODULES,
  AG_GRID_OPTIONS,
  SOURCE_MASTER_TAB_ID,
  TOAST_CONTAINER_ID,
} from "../../../../shared/constants";
import { masterTablesTabActions } from "../../../../store/slices/master-tables-tab-slice";
import useFetch from "../../../../hooks/useFetchMSAL";
import EditableGridActions from "../../../UI/EditableGridActions/EditableGridActions";

const SourceMasterGrid = (props: {
  gridHeight: string;
}) => {
  const [, fetchData] = useFetch([]);

  // States
  const [agRowData, setAgRowData] = useState<SourceMaster[]>([]);
  const [agRowDataCopy, setAgRowDataCopy] = useState<SourceMaster[]>([]);
  const [sourceMasterSlicers, setsourceMasterSlicers] =
    useState<SourceMasterSlicersModel>({
      site_code: [],
      supplier_code: [],
    });
  const [selectedGridSlicers, setSelectedGridSlicers] =
    useState<SourceMasterSlicersModel>(manageSourceMasterSlicersInStorage);
  const [cellEditTracks, setCellEditTracks] =
    useState<PlanningParametersCellEditTrack>({});
  const [siteMasterMapping, setSiteMasterMapping] = useState<{ [key: string]: string; }>({});
  const [supplierMasterMapping, setSupplierMasterMapping] = useState<{ [key: string]: string; }>({});
  const [siteMasterDropdown, setSiteMasterDropdown] = useState<any>([]);
  const [supplierMasterDropdown, setSupplierMasterDropdown] = useState<any>([]);
  const [changeTrackState, setChangeTrackState] = useState(false);
  const [insertedRows, setInsertedRows] = useState<any>({});
  const [selectedRowIds, setSelectedRowIds] = useState<any[]>([]);
  const [newRowCount, setNewRowCount] = useState<number>(1);
  const [enableDelButton, setEnableDelButton] = useState(false);
  const [sourceValues, setSourceValues] = useState<string[]>([]);

  // Variables
  const agGridRef = useRef<AgGridReact>(null);

  // Redux variables
  const dispatch = useDispatch();
  const tabApiDataFetched: boolean = useSelector(
    (state: RootState) => state.masterTables.tabApiDataFetched
  )[SOURCE_MASTER_TAB_ID];
  const activeSubTabId: string = useSelector(
    (state: RootState) => state.masterTables.activeSubTabId
  );
  const isSourceMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isSourceMasterUpdated
  );

  /**
   * Returns whether supplier name cell should be editable or not
   * @param {any} params Editable cell parameters
   * @returns {boolean} Boolean value
   */
  const isSupplierNameCellEditable = (params: any): boolean => {
    if (params.data.site_name) {
      return false;
    }
    return true;
  };

  /**
   * Returns whether site name cell should be editable or not
   * @param {any} params Editable cell parameters
   * @returns {boolean} Boolean value
   */
  const isSiteNameCellEditable = (params: any) => {
    if (params.data.supplier_name) {
      return false;
    }
    return true;
  };

  const ColDef: any[] = [
    {
      headerName: "Source Value",
      field: "source_value",
      cellDataType: "text",
      cellEditor: "agTextCellEditor",
      editable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      suppressHeaderMenuButton: true,
      sortable: false,
      valueParser: (params: any) => {
        try {
          if (sourceValues.includes(params.newValue)) {
            toast.error("Source value must be unique!");
            return "";
          }
          return params.newValue;
        } catch (e) {
          console.error(e);
          return null;
        }
      },
    },
    {
      headerName: "Source Type",
      field: "source_type",
      cellDataType: "text",
      cellEditor: "agSelectCellEditor",
      editable: true,
      suppressHeaderMenuButton: true,
      sortable: false,
      cellEditorParams: { values: ["Buy", "Manufacture", "Transfer"] },
    },
    {
      headerName: "Supplier Name",
      field: "supplier_name",
      cellDataType: "text",
      suppressHeaderMenuButton: true,
      sortable: false,
      editable: isSupplierNameCellEditable,
      cellEditorSelector: function (params: any) {
        if (
          params.data.site_name === "" ||
          params.data.id.startsWith("new_")
        ) {
          return { component: "agSelectCellEditor" };
        }
      },
      cellEditorParams: { values: supplierMasterDropdown },
    },
    {
      headerName: "Site Name",
      field: "site_name",
      cellDataType: "text",
      suppressHeaderMenuButton: true,
      sortable: false,
      editable: isSiteNameCellEditable,
      cellEditorSelector: function (params: any) {
        if (params.data.supplier_name === "") {
          return { component: "agSelectCellEditor" };
        }
      },
      cellEditorParams: { values: siteMasterDropdown },
    },
    {
      headerName: "Transit Time(days)",
      field: "transit_time_days",
      cellDataType: "number",
      editable: true,
      cellEditor: "agNumberCellEditor",
      suppressHeaderMenuButton: true,
      sortable: false,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      width: 150,
      autoHeight: true,
      ...(AG_GRID_DEFAULT_COL_DEF as any),
    };
  }, []);

  /**
   * Fetches source master data from API
   * @param {any} body Request body
   */
  const fetchSourceMaster = useCallback(
    async (body: any): Promise<void> => {
      setChangeTrackState(false);
      const fetchingDataToastId = toast.loading("Fetching Source Master data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });
      try {
        const sourceMasterFetchAPIResponse: AxiosResponse<SourceMasterFetchAPI> =
          await fetchData(
            `/get-source-master`,
            {
              method: 'POST',
              data: body
            }
          );

        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (sourceMasterFetchAPIResponse.data.data) {
          setAgRowData(sourceMasterFetchAPIResponse.data.data);
          setAgRowDataCopy(
            JSON.parse(JSON.stringify(sourceMasterFetchAPIResponse.data.data))
          );
          setSiteMasterDropdown(
            sourceMasterFetchAPIResponse.data.cell_dropdowns.site_code
          );
          setSupplierMasterDropdown(
            sourceMasterFetchAPIResponse.data.cell_dropdowns.supplier_code
          );
          setsourceMasterSlicers(sourceMasterFetchAPIResponse.data.slicers);
          setSiteMasterMapping(
            sourceMasterFetchAPIResponse.data.site_master_mapping
          );
          setSupplierMasterMapping(
            sourceMasterFetchAPIResponse.data.supplier_master_mapping
          );
          setSourceValues(sourceMasterFetchAPIResponse.data.source_values);
          dispatch(
            masterTablesTabActions.setTabApiDataFetched({
              [SOURCE_MASTER_TAB_ID]: true,
            })
          );
        } else {
          toast.error("Error in fetching Source Master data", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } catch (error: Error | any) {
        console.error(`Request Error: ${error}`);
        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });
        toast.error("Error in fetching Source Master data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    },
    [
      dispatch,
      fetchData
    ]
  );

  /**
   * Event handler for when cell editing is stopped
   * @param {any} params Cell editing stop event parameters
   */
  const onCellEditingStoppedHandler = (params: any) => {
    try {
      var id: string = params.node.data.id;
      const colName = params.column.colId;

      if (id.toString().startsWith("new_")) {
        setInsertedRows((prev: any) => {
          let newData = prev;
          let newRow = newData[id] ? newData[id] : {};

          switch (colName) {
            case "site_name":
              newRow["site_code"] = siteMasterMapping[params.newValue];
              break;
            case "supplier_name":
              newRow["supplier_code"] = supplierMasterMapping[params.newValue];
              break;
            default:
              newRow[colName] = params.newValue;
          }

          newData[id] = newRow;
          return newData;
        });
      } else {
        if (
          params.oldValue !== params.newValue &&
          params.newValue !== undefined
        ) {
          dispatch(masterTablesTabActions.setIsMasterTablesEdited({ key: 'sourceMaster', value: true }));
          let currentRowNode = agGridRef.current!.api!.getRowNode(
            params.node.id
          );

          setCellEditTracks((prev) => {
            let newTrackEdits = prev;
            let cellEditTrack = newTrackEdits[id] ? newTrackEdits[id] : {};

            switch (colName) {
              case "site_name":
                cellEditTrack["site_code"] = siteMasterMapping[params.newValue];
                break;
              case "supplier_name":
                cellEditTrack["supplier_code"] = supplierMasterMapping[params.newValue];
                break;
              default:
                cellEditTrack[colName] = params.newValue;
            }

            newTrackEdits[id] = cellEditTrack;
            return newTrackEdits;
          });

          setChangeTrackState(true);
          agGridRef.current!.api!.applyTransaction({
            update: [currentRowNode?.data],
          });
        }
      }
    } catch (error: any) {
      console.error(`Error: ${error}`);
    }
  };

  /**
   * Sets selected rows on selection checkbox change
   */
  const onSelectionChangedHandler = () => {
    const selectedRows = agGridRef.current!.api!.getSelectedRows();
    const selectedRowIds: any[] = selectedRows.map((el) => el.id);
    setSelectedRowIds(selectedRowIds);
  };

  const gridOptions = {
    ...(AG_GRID_OPTIONS as any),
    onSelectionChanged: onSelectionChangedHandler,
    getRowStyle: (params: any) => newGridRowStyleHandler(params, "id")
  };

  /**
   * Inserts a new row into the grid
   */
  const addRowToGrid = (): void => {
    const newRowId: string = `new_${newRowCount.toString()}`;
    agGridRef.current!.api!.applyTransaction({
      add: [{ id: newRowId }],
      addIndex: 0,
    });
    setInsertedRows((insertedRows: any) => {
      return {
        ...insertedRows,
        [newRowId]: {}
      };
    });
    dispatch(masterTablesTabActions.setIsMasterTablesEdited({ key: 'sourceMaster', value: true }));
    setChangeTrackState(true);
    setNewRowCount(newRowCount + 1);
  };

  /**
   * Deletes selected rows from the grid
   */
  const deleteSelectedRowsFromGrid = (): void => {
    deleteSelectedRowData(selectedRowIds);
    setSelectedRowIds([]);
    setEnableDelButton(false);
  };

  /**
   * Deletes selected rows
   * @param {any[]} selectedRowIds Selected Row IDs
   */
  const deleteSelectedRowData = async (selectedRowId: any[]) => {
    let deleteToastId;

    try {
      // Delete selected newly inserted rows
      const oldDataRowIds: any[] = selectedRowIds.filter(rowId => !rowId.toString().startsWith("new_"));
      setInsertedRows((insertedRows: any) => removeInsertedRowsById(insertedRows, selectedRowIds));

      if (oldDataRowIds.length) {
        deleteToastId = toast.loading("Deleting Row...", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("loading"),
        });

        const deleteReqResponse: AxiosResponse =
          await fetchData(
            `/delete-source-master`,
            {
              method: 'DELETE',
              data: { row_ids_to_delete: selectedRowId },
            }
          );

        toast.dismiss({
          id: deleteToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (deleteReqResponse.data.success) {
          setCellEditTracks({});
          setInsertedRows({});

          toast.success("Data updated successfully", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("success"),
          });

          dispatch(
            masterTablesTabActions.setTabApiDataFetched({
              [SOURCE_MASTER_TAB_ID]: false,
            }));

        } else if (deleteReqResponse.data.error) {
          toast.error(deleteReqResponse.data.error, {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } else {
        const selectedRows: any[] | undefined = agGridRef.current?.api.getSelectedRows();
        agGridRef.current?.api.applyTransaction({
          remove: selectedRows
        });
      }
    } catch (error: any) {
      console.error(error);
      toast.error("Something went wrong.", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  /**
   * Updates cell values by using part site source update API
   * @param {any} cellEditTracks Cell edits in the grid
   * @param {any} insertedRows Newly inserted rows
   */
  const updateCellValues = async (
    cellEditTracks: any,
    insertedRows: any
  ): Promise<void> => {
    try {
      const calculateProcessToastId = toast.loading("Updating Source Master data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });

      const updateReqResponse: AxiosResponse =
        await fetchData(
          `/update-source-master`,
          {
            method: 'PUT',
            data: {
              updated_data: cellEditTracks,
              inserted_data: insertedRows
            }
          }
        );

      toast.dismiss({
        id: calculateProcessToastId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (updateReqResponse.data.success) {
        setCellEditTracks({});
        setInsertedRows({});
        dispatch(masterTablesTabActions.setIsMasterTablesEdited({ key: 'sourceMaster', value: false }));

        dispatch(
          masterTablesTabActions.setIsSourceMasterUpdated(
            !isSourceMasterUpdated
          )
        );

        toast.success("Source Master data updated successfully", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("success"),
        });

        dispatch(
          masterTablesTabActions.setTabApiDataFetched({
            [SOURCE_MASTER_TAB_ID]: false,
          }));

      } else if (updateReqResponse.data.error) {
        toast.error(updateReqResponse.data.error, {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: any) {
      console.error(error);
      toast.error("Error in updating the Source Master data", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  /**
   * Event handler for save button click
   * @param {any} event Save button click event data
   */
  const onUpdateCellValuesHandler = async (event: any): Promise<void> => {
    try {
      const updatedNewRowData: any = {};
      for (let key in insertedRows) {
        if (Object.keys(insertedRows[key]).length) {
          updatedNewRowData[key] = insertedRows[key];
        }
      }

      if (
        Object.keys(cellEditTracks).length ||
        Object.keys(updatedNewRowData).length
      ) {
        updateCellValues(cellEditTracks, updatedNewRowData);
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error}`);
    }
  };

  /**
   * Event handler for reset button click
   * @param {any} params Reset button click event data
   */
  const resetCellEdits = async (params: any): Promise<void> => {
    try {
      const resetConsent: boolean = window.confirm(
        "Do you want to reset these changes?"
      );

      if (resetConsent) {
        setCellEditTracks({});
        setChangeTrackState(false);
        setAgRowDataCopy(JSON.parse(JSON.stringify([...agRowData])));
        dispatch(masterTablesTabActions.setIsMasterTablesEdited({ key: 'sourceMaster', value: false }));
        // removeEditedCellHighlights();
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error}`);
    }
  };

  useEffect(() => {
    if (!tabApiDataFetched && activeSubTabId === SOURCE_MASTER_TAB_ID) {
      fetchSourceMaster(selectedGridSlicers);
    }
  }, [
    fetchSourceMaster,
    selectedGridSlicers,
    tabApiDataFetched,
    activeSubTabId,
  ]);

  useEffect(() => {
    if (selectedRowIds.length > 0) {
      setEnableDelButton(true);
    } else {
      setEnableDelButton(false);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (
      Object.keys(insertedRows).length === 0 &&
      Object.keys(cellEditTracks).length === 0
    ) {
      setChangeTrackState(false);
    }
  }, [insertedRows, cellEditTracks]);

  return (
    <>
      <div className="row mb-2">
        <div className="col col-8 text-start">
          <SourceMasterSlicers
            slicers={sourceMasterSlicers}
            selectedSlicers={selectedGridSlicers}
            setSelectedSlicers={setSelectedGridSlicers}
          />
        </div>

        <div className="col col-4 text-end">
          <EditableGridActions
            disableIf={{
              resetBtn: !changeTrackState,
              saveBtn: !changeTrackState,
              deleteRowBtn: !enableDelButton
            }}
            onClick={{
              resetBtn: resetCellEdits,
              saveBtn: onUpdateCellValuesHandler,
              addRowBtn: addRowToGrid,
              deleteRowBtn: deleteSelectedRowsFromGrid
            }}
          />
        </div>
      </div>

      <div
        className="ag-theme-balham mt-2"
        style={{
          height: props.gridHeight,
          maxHeight: props.gridHeight,
          maxWidth: "68vw",
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <AgGridReact
          ref={agGridRef}
          rowData={agRowDataCopy}
          columnDefs={ColDef}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          onCellEditingStopped={onCellEditingStoppedHandler}
          gridOptions={gridOptions}
          modules={AG_GRID_MODULES}
        />
      </div>
    </>
  );
};

export default SourceMasterGrid;
