import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import "ag-charts-enterprise";
import "ag-grid-enterprise";

import { RootState } from "../../../store";
import ReplenishmentPlanSummary from "../../../models/ReplenishmentPlan/ReplenishmentPlanSummary.model";
import { title } from "process";

export const SAndOPCharts = () => {
  const gridRowData: ReplenishmentPlanSummary[] = useSelector(
    (state: RootState) => state.replenishmentTab.summaryGridRowData
  );
  const [option, setOption] = useState({});

  const combineItems = (items: any) => {
    // Create a map to accumulate values by key
    const combinedMap = new Map();

    // Process each item array
    items.forEach((item: any, index: any) => {
      item.forEach(([key, value]: any) => {
        if (!combinedMap.has(key)) {
          // let date = new Date(key);
          combinedMap.set(key, { date: key });
        }
        // Assign values to properties based on the index of the item
        const property = [
          "demand",
          "total_receipts_qty",
          "closing_inventory",
        ][index];
        combinedMap.get(key)[property] = value;
      });
    });
    let res = Array.from(combinedMap.values());
    // res.pop()
    // Convert the map to an array of objects
    return res;
  };

  useEffect(() => {
    const dataNeeded = gridRowData
      .map((obj) => {
        const entries = Object.entries(obj);
        if (entries.length > 0) {
          let neededCols: string[] = [
            "demand",
            "total_receipts_qty",
            "closing_inventory",
          ];

          if (neededCols.includes(entries[entries.length - 1][1] as string)) {
            entries.pop();

            return entries;
          }
        }

        return null;
      })
      .filter((item) => item !== undefined && item !== null);

    const data = combineItems(dataNeeded);

    const formatter = new Intl.NumberFormat("en-US");

    function renderer(params: any) {
      const { datum, xKey, yKey } = params;
      return {
        title: datum[xKey],
        content: formatter.format(datum[yKey]),
      };
    }

    const options: Object = {
      // title: {
      //   text: "S&OP",
      // },
      // dataSource: {getData:data},
      data: data,
      series: [
        {
          type: "bar",
          xKey: "date",
          yKey: "closing_inventory",
          yName: "Closing Inventory",
          tooltip: {
            renderer: renderer,
          },
          fill: "#7DDE92"
        },
        {
          type: "line",
          xKey: "date",
          yKey: "demand",
          yName: "Demand",
          tooltip: {
            renderer: renderer,
          },
          stroke: "#4E4187",
          marker: {
            fill: "#4E4187"
          }
        },
        {
          type: "line",
          xKey: "date",
          yKey: "total_receipts_qty",
          yName: "Total Order Receipts",
          tooltip: {
            renderer: renderer,
          },
          stroke: "#E3170A",
          marker: {
            fill: "#E3170A"
          }
        },
      ],
      zoom: {
        enabled: true,
        buttons: {
          enabled: false,
        },
      },
      axes: [
        {
          type: "category",
          position: "bottom",
          keys: ["date"],
          nice: true,
          label: {
            format: "%-m/%-d/%Y",
            avoidCollisions: true,
            rotation: 0,
          },
          // title:{
          //   enabled: true,
          //   text: "Weeks"
          // },
          tick: {
            enabled: true,
            size: 15,
          },
        },
        {
          type: "number",
          position: "left",
          keys: ["demand", "total_receipts_qty"],
          title: {
            enabled: true,
            text: "Total Order Receipts",
          },
          label: {
            formatter: (params: any) => formatter.format(params.value), // Format the Y-axis labels with units
          },
        },
        {
          type: "number",
          position: "right",
          keys: ["closing_inventory"],
          title: {
            enabled: true,
            text: "Closing Inventory",
          },
          label: {
            formatter: (params: any) => params.value, // Format the Y-axis labels with units
          },
        },
      ],
      //   navigator:{
      //     enabled: true
      //   }
    };
    setOption(options);
  }, [gridRowData]);

  const options = option;

  return (
    <>
      {/* <AgCharts options={options} /> */}
      <div
        style={{
          height: "270px",
          maxHeight: "500px",
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <AgChartsReact options={options} />
      </div>
    </>
  );
};