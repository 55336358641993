import { AgGridReact } from "ag-grid-react";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useFetch from "../../../hooks/useFetchMSAL";
import { PartSiteSourceFetchAPI } from "../../../models/APIResponses.model";
import PartSiteSource from "../../../models/PlanningParameters/PartSiteSource.model";
import PartSiteSourceMapping from "../../../models/PlanningParameters/PartSiteSourceMapping.model";
import PartSiteSourceSlicersModel from "../../../models/PlanningParameters/PartSiteSourceSlicers.model";
import PlanningParametersCellEditTrack from "../../../models/PlanningParameters/PlanningParametersCellEditTrack.model";
import {
  AG_GRID_DEFAULT_COL_DEF,
  AG_GRID_MODULES,
  AG_GRID_OPTIONS,
  BUY_PLAN_TAB_ID,
  PART_SITE_SOURCE_TAB_ID,
  TOAST_CONTAINER_ID,
} from "../../../shared/constants";
import { getToastOptions, newGridRowStyleHandler, removeInsertedRowsById } from "../../../shared/functions";
import { RootState } from "../../../store";
import { planningParametersTabActions } from "../../../store/slices/planning-parameters-tab-slice";
import { replenishmentTabActions } from "../../../store/slices/replenishment-tab-slice";
import "../../../styles.css";
import EditableGridActions from "../../UI/EditableGridActions/EditableGridActions";

const PartSiteSourceTable = (props: {
  gridHeight: string;
  getPartSiteSourceSlicers: (slicers: PartSiteSourceSlicersModel) => void;
  getPartSiteSourceMappings: (mappings: PartSiteSourceMapping[]) => void;
}) => {
  const [, fetchData] = useFetch([]);

  // States
  const [agRowData, setAgRowData] = useState<PartSiteSource[]>([]);
  const [agRowDataCopy, setAgRowDataCopy] = useState<PartSiteSource[]>([]);
  const [partCodeValues, setPartCodeValues] = useState<string[]>([]);
  const [locationNameValues, setLocationNameValues] = useState<string[]>([]);
  const [sourceValues, setSourceValues] = useState<string[]>([]);
  const [safetyStockPolicyValues, setSafetyStockPolicyValues] = useState<
    string[]
  >([]);
  const [orderPolicyValues, setOrderPolicyValues] = useState<string[]>([]);
  const [locationMapping, setLocationMapping] = useState<{ [key: string]: string; }>({});
  const [partMasterMapping, setPartMasterMapping] = useState<{ [key: string]: string; }>({});
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [changeTrackState, setChangeTrackState] = useState(false);
  const [insertedRows, setInsertedRows] = useState<any>({});
  const [selectedRowIds, setSelectedRowIds] = useState<any[]>([]);
  const [newRowCount, setNewRowCount] = useState<number>(1);
  const [enableDelButton, setEnableDelButton] = useState(false);
  const [cellEditTracks, setCellEditTracks] =
    useState<PlanningParametersCellEditTrack>({});

  // Redux variables
  const dispatchFn = useDispatch();
  const selectedGridSlicers = useSelector(
    (state: RootState) => state.planningParametersTab.selectedGridSlicers
  );
  const activeUpperSubTabId: string = useSelector((state: RootState) => state.planningParametersTab.activeSubTabId.upper);
  const tabApiDataFetched: boolean = useSelector((state: RootState) => state.planningParametersTab.tabApiDataFetched)[PART_SITE_SOURCE_TAB_ID];
  const isOrderPolicyUpdated = useSelector(
    (state: RootState) => state.planningParametersTab.isOrderPolicyUpdated
  );
  const isSafetyStockPolicyUpdated = useSelector(
    (state: RootState) =>
      state.planningParametersTab.isSafetyStockPolicyUpdated
  );

  const isPartMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isPartMasterUpdated
  );
  const isSiteMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isSiteMasterUpdated
  );
  const isSourceMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isSourceMasterUpdated
  );

  // Variables
  const agGridRef = useRef<AgGridReact>(null);
  const { getPartSiteSourceSlicers, getPartSiteSourceMappings } = { ...props };
  const gridColDef: any[] = [
    {
      headerName: "Part Name",
      field: "part_code",
      cellDataType: "text",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: { values: partCodeValues },
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    {
      headerName: "Location Name",
      field: "location_name",
      cellDataType: "text",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: { values: locationNameValues },
    },
    {
      headerName: "Source Value",
      field: "source_value",
      cellDataType: "text",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: { values: sourceValues },
    },
    {
      headerName: "Pre Shipment Lead Time",
      field: "pre_shipment_lead_time",
      cellDataType: "number",
    },
    {
      headerName: "Post Shipment Lead Time",
      field: "post_shipment_lead_time",
      cellDataType: "number",
    },
    {
      headerName: "Safety Stock Policy",
      field: "safety_stock_policy",
      cellDataType: "text",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: { values: safetyStockPolicyValues },
    },
    {
      headerName: "Order Policy",
      field: "order_policy",
      cellDataType: "text",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: { values: orderPolicyValues },
      width: 180
    },
    {
      headerName: "InCoTerms",
      field: "encoterms",
      cellDataType: "text",
      cellEditor: 'agTextCellEditor',
    },
    {
      headerName: "Days Before Payment",
      field: "days_before_payment",
      cellDataType: "number",
      cellEditor: 'agNumberCellEditor',
    },
    {
      headerName: "Landed Cost",
      field: "landed_cost",
      cellDataType: "number",
      cellEditor: 'agNumberCellEditor',
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      width: 140,
      editable: true,
      ...(AG_GRID_DEFAULT_COL_DEF as any),
    };
  }, []);


  /**
   * Fetches part site source data from API
   * @param {any} body Request body
   */
  const fetchPartSiteSource = useCallback(
    async (body: any): Promise<void> => {
      setChangeTrackState(false);
      const fetchingDataToastId = toast.loading("Fetching Part Site Source data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });
      try {
        const fetchAPIResponse: AxiosResponse<PartSiteSourceFetchAPI> =
          await fetchData(`/get-part-site-source`,
            {
              method: 'POST',
              data: body,
            });

        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (fetchAPIResponse.data.data) {
          setAgRowData(fetchAPIResponse.data.data);
          setAgRowDataCopy(
            JSON.parse(JSON.stringify(fetchAPIResponse.data.data))
          );
          getPartSiteSourceSlicers(fetchAPIResponse.data.slicers);
          setPartCodeValues(
            fetchAPIResponse.data.cell_dropdowns.part_name
          );
          setLocationNameValues(
            fetchAPIResponse.data.cell_dropdowns.location_name
          );
          setOrderPolicyValues(
            fetchAPIResponse.data.cell_dropdowns.order_policy
          );
          setSourceValues(
            fetchAPIResponse.data.cell_dropdowns.source_value
          );
          setLocationMapping(
            fetchAPIResponse.data.location_mapping
          );
          setSafetyStockPolicyValues(
            fetchAPIResponse.data.cell_dropdowns
              .safety_stock_policy
          );
          setPartMasterMapping(
            fetchAPIResponse.data.part_master_mapping
          );

          getPartSiteSourceMappings(
            fetchAPIResponse.data.part_site_source_mapping
          );
          dispatchFn(planningParametersTabActions.setTabApiDataFetched({
            [PART_SITE_SOURCE_TAB_ID]: true
          }));

        } else {
          toast.error("Error in fetching Part Site Source data", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } catch (error: Error | any) {
        console.error(`Request Error: ${error}`);
        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });
        toast.error("Error in fetching Part Site Source data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    },
    [
      dispatchFn,
      fetchData,
      getPartSiteSourceSlicers,
      getPartSiteSourceMappings
    ]
  );

  /**
   * Event handler for when cell editing is stopped
   * @param {any} params Cell editing stop event parameters
   */
  const onCellEditingStoppedHandler = (params: any) => {
    try {
      var id: string = params.node.data.id;
      const colName = params.column.colId;

      if (id !== undefined && id.toString().startsWith("new_")) {
        setInsertedRows((prev: any) => {
          let newData = prev;
          let newRow = newData[id] ? newData[id] : {};

          switch (colName) {
            case "location_name":
              newRow["site_code"] = locationMapping[params.newValue];
              break;
            case "part_name":
              newRow["part_code"] = partMasterMapping[params.newValue];
              break;
            default:
              newRow[colName] = params.newValue;
          }

          newData[id] = newRow;
          return newData;
        });
      } else {
        if (params.oldValue !== params.newValue && params.newValue !== undefined) {
          let currentRowNode = agGridRef.current!.api!.getRowNode(params.node.id);

          setCellEditTracks((prev) => {
            let newTrackEdits = prev;
            let cellEditTrack = newTrackEdits[id] ? newTrackEdits[id] : {};

            switch (colName) {
              case "location_name":
                cellEditTrack["site_code"] = locationMapping[params.newValue];
                break;
              case "part_name":
                cellEditTrack["part_code"] = partMasterMapping[params.newValue];
                break;
              default:
                cellEditTrack[colName] = params.newValue;
            }

            newTrackEdits[id] = cellEditTrack;
            return newTrackEdits;
          });
          dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'partSiteSource', value: true }));

          setChangeTrackState(true);
          agGridRef.current!.api!.applyTransaction({
            update: [currentRowNode?.data],
          });
        } else if (params.newValue === params.oldValue) {
          dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'partSiteSource', value: false }));

        }
      }

    } catch (error: any) {
      console.error(`Error: ${error}`);
    }
  };

  /**
   * Sets selected rows on selection checkbox change
   */
  const onSelectionChangedHandler = () => {
    const selectedRows = agGridRef.current!.api!.getSelectedRows();
    const selectedRowIds: any[] = selectedRows.map((el) => el.id);
    setSelectedRowIds(selectedRowIds);
  };

  /**
   * Deletes selected rows
   * @param {any[]} selectedRowIds Selected Row IDs
   */
  const deleteSelectedRowData = async (selectedRowIds: any[]) => {
    let deleteRowsToastId;

    try {
      // Delete selected newly inserted rows
      const oldDataRowIds: any[] = selectedRowIds.filter(rowId => !rowId.toString().startsWith("new_"));
      setInsertedRows((insertedRows: any) => removeInsertedRowsById(insertedRows, selectedRowIds));

      if (oldDataRowIds.length) {
        deleteRowsToastId = toast.loading("Deleting selected rows...", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("loading"),
        });

        const deleteReqResponse: AxiosResponse =
          await fetchData(
            `/delete-part-site-source`,
            {
              method: 'DELETE',
              data: { row_ids_to_delete: oldDataRowIds },
            }
          );

        toast.dismiss({
          id: deleteRowsToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (deleteReqResponse.data.success) {
          setCellEditTracks({});
          setInsertedRows({});

          toast.success("Data updated successfully", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("success"),
          });

          setIsDataUpdated((prevDataUpdate) => !prevDataUpdate);

        } else if (deleteReqResponse.data.error) {
          toast.error(deleteReqResponse.data.error, {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } else {
        const selectedRows: any[] | undefined = agGridRef.current?.api.getSelectedRows();
        agGridRef.current?.api.applyTransaction({
          remove: selectedRows
        });
      }
    } catch (error: any) {
      console.error(error);

      if (deleteRowsToastId) {
        toast.error("Failed to delete the selected rows", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    }
  };

  /**
   * Updates cell values by using part site source update API
   * @param {any} cellEditTracks Cell edits in the grid
   * @param {any} insertedRows Newly inserted rows
   */
  const updateCellValues = async (
    cellEditTracks: any,
    insertedRows: any
  ): Promise<void> => {
    try {
      const updateToastId = toast.loading("Updating Part Site Source data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });

      const updateReqResponse: AxiosResponse =
        await fetchData(`/update-part-site-source`,
          {
            method: 'PUT',
            data: {
              updated_data: cellEditTracks,
              inserted_data: insertedRows
            }
          }
        );

      toast.dismiss({
        id: updateToastId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (updateReqResponse.data.success) {
        setCellEditTracks({});
        setInsertedRows({});

        
        dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'partSiteSource', value: false }));
        
        toast.success("Part Site Source data updated successfully", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("success"),
        });
        
        dispatchFn(planningParametersTabActions.setTabApiDataFetched({
          [PART_SITE_SOURCE_TAB_ID]: false
        }));
        
        dispatchFn(replenishmentTabActions.setTabApiDataFetched({
          [BUY_PLAN_TAB_ID]: false
        }));
        setIsDataUpdated(true);

      } else {
        toast.error(updateReqResponse.data.error, {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: any) {
      console.error(error);
      toast.error("Error in updating the Part Site Source data", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  /**
   * Event handler for save button click
   * @param {any} event Save button click event data
   */
  const onUpdateCellValuesHandler = async (event: any): Promise<void> => {
    try {
      const updatedNewRowData: any = {};
      for (let key in insertedRows) {
        if (Object.keys(insertedRows[key]).length) {
          updatedNewRowData[key] = insertedRows[key];
        }
      }

      if (
        Object.keys(cellEditTracks).length ||
        Object.keys(updatedNewRowData).length
      ) {
        updateCellValues(cellEditTracks, updatedNewRowData);

      }
    } catch (error: Error | any) {
      console.error(`Error: ${error}`);
    }
  };

  /**
   * Event handler for reset button click
   * @param {any} params Reset button click event data
   */
  const resetCellEdits = async (event: any): Promise<void> => {
    try {
      const resetConsent: boolean = window.confirm(
        "Do you want to reset these changes?"
      );

      if (resetConsent) {
        setCellEditTracks({});
        setChangeTrackState(false);
        setAgRowDataCopy(JSON.parse(JSON.stringify([...agRowData])));
        dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'partSiteSource', value: false }));
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error}`);
    }
  };

  const gridOptions = {
    ...(AG_GRID_OPTIONS as any),
    onSelectionChanged: onSelectionChangedHandler,
    getRowStyle: (params: any) => newGridRowStyleHandler(params, "id")
  };

  /**
   * Inserts a new row into the grid
   */
  const addRowToGrid = (): void => {
    const newRowId: string = `new_${newRowCount.toString()}`;
    agGridRef.current!.api!.applyTransaction({
      add: [{ id: newRowId }],
      addIndex: 0,
    });
    setInsertedRows((insertedRows: any) => {
      return {
        ...insertedRows,
        [newRowId]: {}
      };
    });
    setChangeTrackState(true);
    setNewRowCount((rowCount) => (rowCount + 1));
    dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'partSiteSource', value: true }));
  }

  /**
   * Deletes selected rows from the grid
   */
  const deleteSelectedRowsFromGrid = (): void => {
    deleteSelectedRowData(selectedRowIds);
    setSelectedRowIds([]);
    setEnableDelButton(false);
  }

  useEffect(() => {
    if (
      !tabApiDataFetched &&
      activeUpperSubTabId === PART_SITE_SOURCE_TAB_ID
    ) {
      fetchPartSiteSource(selectedGridSlicers);
    }
  }, [
    fetchPartSiteSource,
    tabApiDataFetched,
    activeUpperSubTabId,
    selectedGridSlicers,
    isDataUpdated,
    isOrderPolicyUpdated,
    isSafetyStockPolicyUpdated,
    isPartMasterUpdated,
    isSiteMasterUpdated,
    isSourceMasterUpdated
  ]);

  useEffect(() => {
    if (selectedRowIds.length > 0) {
      setEnableDelButton(true);
    } else {
      setEnableDelButton(false);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (
      Object.keys(insertedRows).length === 0 &&
      Object.keys(cellEditTracks).length === 0
    ) {
      setChangeTrackState(false);
    }
  }, [insertedRows, cellEditTracks]);

  return (
    <>
      <div className="row mb-2">
        <div className="col offset-8 col-4 text-end">
          <EditableGridActions
            disableIf={{
              resetBtn: !changeTrackState,
              saveBtn: !changeTrackState,
              deleteRowBtn: !enableDelButton
            }}
            onClick={{
              resetBtn: resetCellEdits,
              saveBtn: onUpdateCellValuesHandler,
              addRowBtn: addRowToGrid,
              deleteRowBtn: deleteSelectedRowsFromGrid
            }}
          />
        </div>
      </div>

      <div
        className="ag-theme-balham mt-2"
        style={{
          height: props.gridHeight,
          maxHeight: props.gridHeight,
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <AgGridReact
          ref={agGridRef}
          rowData={agRowDataCopy}
          columnDefs={gridColDef}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          onCellEditingStopped={onCellEditingStoppedHandler}
          gridOptions={gridOptions}
          modules={AG_GRID_MODULES}
        />
      </div>
    </>
  );
};

export default PartSiteSourceTable;

